import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Flight from "../../models/flight";
import { getFlights } from "../../store/flightSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { Player } from "@lottiefiles/react-lottie-player";
import LoadingComponent from "../../components/LoadingComponent";
import { selectedOrganization } from "../../store/organizationsSlice";
import { getDisplayableTextFromFlightPlanPoint } from "../../models/flightPlan";
import { getCrewLastNameWithInitials } from "../../models/crew";

function FlyListFlightsPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { allFlights, status, loading } = useAppSelector((state) => state.flights);
  const [filteredFlights, setFilteredFlights] = useState<Flight[]>([]);

  const [selectedTab, setSelectedTab] = useState<"today" | "tomorrow" | "unsigned">("today")
  const selectedOrg = useAppSelector(selectedOrganization)
  
  useEffect(() => {
    if (status === "idle") {
      dispatch(getFlights());
    }
  }, [dispatch, status]);

  useEffect(() => {
      if (selectedTab === "today") {
          setFilteredFlights(allFlights.filter(f => moment(f.departureDateTime).utc().isSame(moment().utc(), "day")))
      } else if (selectedTab === "tomorrow") {
          setFilteredFlights(allFlights.filter(f => moment(f.departureDateTime).utc().isSame(moment().utc().add(1, "day"), "day")))
      } else if (selectedTab === "unsigned") {
          setFilteredFlights(allFlights.filter(f => f.briefingPilotAccept === undefined && f.briefing?.status === "ready"))
      }
  }, [allFlights, selectedTab])

  return (
    <Container>
      <h1 className="pt-5">Flights</h1>
      <Row>
        <nav className="nav">
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("today");
            }}
            className={"nav-link underline".concat(selectedTab === "today" ? " active" : "")}
          >
            Today
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("tomorrow");
            }}
            className={"nav-link underline".concat(selectedTab === "tomorrow" ? " active" : "")}
          >
            Tomorrow
          </Link>
          <Link
            to="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedTab("unsigned");
            }}
            className={"nav-link underline".concat(selectedTab === "unsigned" ? " active" : "")}
          >
            Unsigned
          </Link>
        </nav>
      </Row>
      {loading ? (
        <LoadingComponent text="Loading flights..." />
      ) : (
        <>
          {filteredFlights.length === 0 ? (
            <NoFlightsComponent />
          ) : (
            <Table hover>
              <tbody>
                {filteredFlights.map((flight) => {
                  return (
                    <tr key={flight.id} onClick={() => navigate("./" + flight.id)}>
                      <td>
                        <Row>
                          <Col xs={4}>
                            <h4>{moment(flight.departureDateTime).utc().format("HH:mm")}</h4>
                          </Col>
                          <Col xs={8} className="text-end">
                            {flight.flightCategory === "Training" ? (
                              <strong>
                               {flight.trainingFlightCategory!}
                              </strong>
                            ) : (
                              <>
                                <strong>{flight.passengers.length}</strong>
                                {" PAX"}
                              </>
                            )}
                          </Col>
                          <Col xs={12}>
                            {flight.aircraft.registration.replace("-", "") !== flight.flightId ? (
                              <>
                                <strong>{flight.flightId}</strong> - {flight.aircraft.registration}
                              </>
                            ) : (
                              <strong>{flight.aircraft.registration}</strong>
                            )}
                            {selectedOrg?.role === "admin" && ` - ${getCrewLastNameWithInitials(flight.pic)}`}
                          </Col>
                          {flight.flightCategory === "Training" && (
                            <Col xs={12}>
                              <strong>{getCrewLastNameWithInitials(flight.pic)}</strong>
                              {flight.trainingFlightCategory === "Dual" && flight.studentPilot && ` - ${getCrewLastNameWithInitials(flight.studentPilot)}`}
                              {flight.trainingFlightCategory === "Solo" && flight.soloSupervisor && ` - ${getCrewLastNameWithInitials(flight.soloSupervisor)}`}
                              {flight.trainingFlightCategory === "Checkride" && flight.sic && ` - ${getCrewLastNameWithInitials(flight.sic)}`}
                            </Col>
                          )}
                          <Col xs={12}>
                            {flight.route && (
                              <>
                                {[flight.route.departure, ...(flight.route.waypoints ?? []), flight.route.destination]
                                  .map((w) => w.name)
                                  .join(" ➜ ")}
                              </>
                            )}
                            {flight.customRoute && (
                              <>
                                {[
                                  getDisplayableTextFromFlightPlanPoint(flight.customRoute.departure),
                                  ...(flight.customRoute.waypoints ?? []).map((w) => w.name),
                                  getDisplayableTextFromFlightPlanPoint(flight.customRoute.destination),
                                ].join(" ➜ ")}
                              </>
                            )}
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </>
      )}
    </Container>
  );
}

export default FlyListFlightsPage

function NoFlightsComponent() {
  return (
    <div className="d-flex flex-column align-items-center pt-5">
      <Player
        autoplay
        loop
        src="/assets/brief/lottie/no_flights.json"
        speed={1.5}
        background="transparent"
        style={{ height: "200px", width: "200px" }}
      />
      <strong>No flights in this period</strong>
    </div>
  );
}