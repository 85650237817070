import { useEffect, useState } from "react";
import { Button, Col, Container, Modal, Offcanvas, Row } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import TextAreaBox from "../../components/InputBox/TextAreaBox";
import { FlightFplFile } from "../../models/flight";
import FlatPicker from "react-flatpickr";
import moment from "moment";
import { Player } from "@lottiefiles/react-lottie-player";
import { useAppSelector } from "../../store/hooks";
import { selectedOrganization } from "../../store/organizationsSlice";

export interface BaseFplData {
  fplFile: FlightFplFile;
  flightPlanPicDetails: string;
  departureDateTime: Date;
  flightId: string;
}

interface BaseFplPageProps {
  data: BaseFplData;
  getPdfDoc: () => Promise<any>;
  fileFplUsingAviTracerAction: () => Promise<any>;
  refileFplAction: () => Promise<any>;
  chgFplAction: (f15: string, f18: string) => Promise<any>;
  cancelFiledFplAction: () => Promise<any>;
  delayFiledFplAction: (newTime: Date) => Promise<any>;
  goBack: () => void
}

function BaseFplPage({ data, getPdfDoc, fileFplUsingAviTracerAction, refileFplAction, chgFplAction, cancelFiledFplAction, delayFiledFplAction, goBack }: BaseFplPageProps) {

  const { fplFile, flightPlanPicDetails, departureDateTime, flightId } = data;

  const [icaoPdfData, setIcaoPdfData] = useState<Blob | null>(null);

  const selectedOrg = useAppSelector(selectedOrganization);

  const [searchParams] = useSearchParams();
  const [showCancelFplModal, setShowCancelFplModal] = useState(searchParams.has("cancel"));
  const [showDelayFplModal, setShowDelayFplModal] = useState(searchParams.has("delay"));
  const [showRefileFplModal, setShowRefileFplModal] = useState(false);
  const [showChgFplModal, setShowChgFplModal] = useState(searchParams.has("change"));

  const [showSendingAFTN, setShowSendingAFTN] = useState(false);

  useEffect(() => {
    const loadPdf = async () => {
      const pdfData = await getPdfDoc();
      const pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      setIcaoPdfData(pdfBlob);
    };
    loadPdf();
  }, [getPdfDoc]);

  const fplCodeTextBoxContent = () => {
    var fpl = "";
    if (fplFile.status === "ready-to-file" || fplFile.status === "filed" || fplFile.status === "not-filed") {
      fpl = fplFile.fplCode;
    } else if (fplFile.status === "delayed") {
      fpl = fplFile.initialFplCode;
    }

    if (fpl.length > 0){
      fpl = fpl.split("-").slice(0, 3).join("-") + "\n-" + fpl.split("-").slice(3, 5).join("-") + "\n-" + fpl.split("-").slice(5).join("\n-");
    }
    return fpl;
  };

  const fplCodeTextErrorFieldState = () => {
    if (fplFile.status === "error") {
      return { error: { type: "invalid", message: fplFile.error }, invalid: true, isTouched: false, isDirty: false };
    } else if (fplFile.status === "not-filed") {
      return { error: { type: "invalid", message: "Flight plan not filed" }, invalid: true, isTouched: false, isDirty: false };
    }
    return { error: undefined, invalid: false, isTouched: false, isDirty: false };
  };

  const fileFplUsingAviTracer = async () => {
    setShowSendingAFTN(true);

    await fileFplUsingAviTracerAction();

    goBack();
  };

  const refileFpl = async () => {
    setShowRefileFplModal(false);
    setShowSendingAFTN(true);

    await refileFplAction();

    goBack();
  };

  const cancelFiledFpl = async () => {
    setShowCancelFplModal(false);
    setShowSendingAFTN(true);

    await cancelFiledFplAction();

    goBack();
  };

  const chgFpl = async (f15: string, f18: string) => {
    setShowChgFplModal(false);
    setShowSendingAFTN(true);

    await chgFplAction(f15, f18);

    goBack();
  };

  const delayFiledFpl = async (newTime: Date) => {
    setShowDelayFplModal(false);
    setShowSendingAFTN(true);

    await delayFiledFplAction(newTime);

    goBack();
  };

  return (
    <>
      <Modal show size="xl" scrollable contentClassName="h-100" fullscreen={"lg-down"}>
        <Modal.Header closeButton onHide={goBack}>
          <Modal.Title>
            {flightId} - {moment(departureDateTime).utc().format("DD/MM HH:mm")} - {flightPlanPicDetails}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={"p-0"}>
          <Container fluid className="h-100">
            <Row className="h-100">
              <Col xs={12} md={5} className="p-3">
                <Row className="gy-3 gx-2">
                  <Col xs={12}>
                    <MobileShowPdfButtonComponent icaoPdfData={icaoPdfData} data={data} />
                    <TextAreaBox
                      name="ICAO FPL Code"
                      value={fplCodeTextBoxContent()}
                      onChange={(v) => {}}
                      isDisabled={true}
                      rows={16}
                      fieldState={fplCodeTextErrorFieldState()}
                    />
                  </Col>
                  <Col xs={fplFile.status === "filed" || fplFile.status === "delayed" ? 9 : 12}>
                    <div className="d-grid gap-2">
                      {fplFile.status === "ready-to-file" && (
                        <Button variant="dark" onClick={fileFplUsingAviTracer} disabled={!selectedOrg?.billing.canFileFpl}>
                          {selectedOrg?.billing.canFileFpl ? "File FPL using AviTracer" : "No credits available"}
                        </Button>
                      )}
                      {(fplFile.status === "filed" || fplFile.status === "delayed") && (
                        <Button variant="dark" disabled>
                          Flight Plan Filed @
                          {fplFile.status === "filed" && <>{moment(fplFile.fillingTime).utc().format(" DD/MM/YY HH:mm ")} UTC</>}
                          {fplFile.status === "delayed" && <>{moment(fplFile.initialFillingTime).utc().format(" DD/MM/YY HH:mm ")}UTC</>}
                        </Button>
                      )}
                    </div>
                  </Col>
                  {(fplFile.status === "filed" || fplFile.status === "delayed") && (
                    <>
                      <Col xs={3}>
                        <div className="d-grid gap-2">
                          <Button variant="dark" onClick={() => setShowRefileFplModal(true)}>
                            Re-File
                          </Button>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="d-grid gap-2">
                          <Button onClick={() => setShowDelayFplModal(true)}>Delay</Button>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="d-grid gap-2">
                          <Button className="border" variant="light" onClick={() => setShowChgFplModal(true)}>
                            Change
                          </Button>
                        </div>
                      </Col>
                      <Col xs={4}>
                        <div className="d-grid gap-2">
                          <Button variant="danger" onClick={() => setShowCancelFplModal(true)}>
                            Cancel
                          </Button>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col md={7} className="d-none d-md-block">
                {icaoPdfData && (
                  <iframe title="pdf" width="100%" height="100%" src={window.URL.createObjectURL(icaoPdfData) + "#toolbar=0"}></iframe>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        {showSendingAFTN && (
          <div className="w-100 h-100 position-absolute" style={{ inset: "0px", opacity: 0.8, background: "white" }}>
            <div className="d-flex flex-column align-items-center  position-absolute top-50 start-50 translate-middle">
              <Player
                autoplay
                loop
                src="/assets/brief/lottie/sending_aftn.json"
                background="transparent"
                style={{ height: "200px", width: "200px" }}
              />
              <strong>Sending AFTN message</strong>
            </div>
          </div>
        )}
      </Modal>
      <Offcanvas
        backdrop={"static"}
        show={showCancelFplModal || showDelayFplModal || showRefileFplModal || showChgFplModal}
        placement={"end"}
      >
        {showCancelFplModal && <CancelFplComponent onClose={() => setShowCancelFplModal(false)} onCancel={cancelFiledFpl} />}
        {showDelayFplModal && (
          <DelayFplComponent
            previousDepartureTime={new Date(departureDateTime)}
            onClose={() => setShowDelayFplModal(false)}
            onDelay={delayFiledFpl}
          />
        )}
        {showRefileFplModal && <RefileFplComponent onClose={() => setShowRefileFplModal(false)} onRefile={refileFpl} />}
        {showChgFplModal && <ChgFplComponent onClose={() => setShowChgFplModal(false)} onChg={chgFpl} fplFile={fplFile} />}
      </Offcanvas>
    </>
  );
}

export default BaseFplPage;

interface RefileFplComponentProps {
  onClose: () => void;
  onRefile: () => void;
}

function RefileFplComponent({ onClose, onRefile }: RefileFplComponentProps) {
  return (
    <Container className="pt-5">
      <Row>
        <Col xs={12}>
          <h3>Re-File FPL?</h3>
        </Col>
        <Col xs={12}>Are you sure you want to refile your flight plan? Refiling will resend the <strong>INITIAL plan</strong> to all AFTN addresses.<br/> Please proceed with refile <strong>ONLY if an ATC has specifically requested it</strong> due to not receiving your initial plan.</Col>
        <Row className="pt-4">
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={onRefile} variant="dark">
                Re-File FPL
              </Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={onClose} variant="outline-secondary">
                No
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

interface CancelFplComponentProps {
  onClose: () => void;
  onCancel: () => void;
}

function CancelFplComponent({ onClose, onCancel }: CancelFplComponentProps) {
  return (
    <Container className="pt-5">
      <Row>
        <Col xs={12}>
          <h3>Cancel FPL?</h3>
        </Col>
        <Col xs={12}>Are you sure you want to cancel this flight plan?</Col>
        <Row className="pt-4">
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={onCancel} variant="danger">
                Cancel FPL
              </Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={onClose} variant="outline-secondary">
                No
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}

interface DelayFplComponentProps {
  onClose: () => void;
  onDelay: (newTime: Date) => void;
  previousDepartureTime: Date;
}

function DelayFplComponent({ onClose, onDelay, previousDepartureTime }: DelayFplComponentProps) {
  const previousDepartureTimeUtc = new Date(
    previousDepartureTime.getUTCFullYear(),
    previousDepartureTime.getUTCMonth(),
    previousDepartureTime.getUTCDate(),
    previousDepartureTime.getUTCHours(),
    previousDepartureTime.getUTCMinutes()
  );

  const [newDepartureTime, setNewDepartureTime] = useState(previousDepartureTimeUtc);

  return (
    <Container className="pt-5">
      <Row className="gy-4">
        <Col xs={12}>
          <h3>Delay FPL?</h3>
        </Col>
        <Col xs={12}>Are you sure you want to delay this flight plan?</Col>
        <Col xs={12}>
          <FlatPicker
            value={newDepartureTime}
            options={{
              defaultDate: newDepartureTime,
              enableTime: true,
              noCalendar: true,
              time_24hr: true,
              static: true,
            }}
            onChange={(s) => setNewDepartureTime(s[0])}
            className="form-control bg-white"
            render={({ value, ...props }, ref) => {
              return (
                <div className={"input-container rounded-lg"} style={{ backgroundColor: "#F7F7F7" }}>
                  <input ref={ref} className="bg-transparent" readOnly />
                  <label className={value ? "filled" : ""}>{"New Departure Time (UTC)"}</label>
                </div>
              );
            }}
          />
        </Col>
        <Col xs={6}>
          <div className="d-grid gap-2">
            <Button onClick={() => onDelay(new Date(newDepartureTime.getTime() - newDepartureTime.getTimezoneOffset() * 60000))}>
              Delay FPL
            </Button>
          </div>
        </Col>
        <Col xs={6}>
          <div className="d-grid gap-2">
            <Button onClick={onClose} variant="outline-secondary">
              No
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

interface ChgFplComponentProps {
  onClose: () => void;
  onChg: (f15: string, f18: string) => void;
  fplFile: FlightFplFile;
}

function ChgFplComponent({ onClose, onChg, fplFile }: ChgFplComponentProps) {

  const getFieldValue = (field: "f15" | "f18") => {
    if (fplFile.status !== "filed" && fplFile.status !== "delayed") {
      return "";
    }
    const code = fplFile.status === "filed" ? fplFile.fplCode : fplFile.initialFplCode;
    const matches = code.match(/\(FPL-(?<reg>[^-]*)-(?:[^-]*-){3}(?<dep>[^-]*)-\w* (?<f15>[^-]*)-(?<dest>[^-]{4})([^-]*-)(?<f18a>[^-]*)(?<dof>DOF\/\d{6}) ?(?<f18b>[^-]*)-/);
    if (matches && matches.groups) {
      if (field === "f18"){
        return (matches.groups.f18a ?? "") + (matches.groups.f18b ?? "");
      }
      return matches.groups[field];
    }
    return "";
  }
  
  const [initialFieldRoute] = useState<string>(getFieldValue("f15"));
  const [initialFieldOther] = useState<string>(getFieldValue("f18"));

  const [fieldRoute, setFieldRoute] = useState<string>(getFieldValue("f15"));
  const [fieldOther, setFieldOther] = useState<string>(getFieldValue("f18"));

  return (
    <Container className="pt-5">
      <Row className="gy-4">
        <Col xs={12}>
          <h3>Change FPL?</h3>
        </Col>
        <Col xs={12}>Are you sure you want to change this flight plan?</Col>
        <Col xs={12}>
          <TextAreaBox
            isDisabled={false}
            value={fieldRoute}
            onChange={(v) => setFieldRoute(v)}
            name="Route - Field 15"
            uppercase={true}
            fieldState={
              initialFieldRoute !== fieldRoute
                ? {
                    error: { message: "Previous Value: " + initialFieldRoute, type: "invalid" },
                    invalid: true,
                    isTouched: false,
                    isDirty: false,
                  }
                : undefined
            }
          />
        </Col>
        <Col xs={12}>
          <TextAreaBox
            isDisabled={false}
            value={fieldOther}
            onChange={(v) => setFieldOther(v)}
            name="Other - Field 18"
            uppercase={true}
            fieldState={
              initialFieldOther !== fieldOther
                ? {
                    error: { message: "Previous Value: " + initialFieldOther, type: "invalid" },
                    invalid: true,
                    isTouched: false,
                    isDirty: false,
                  }
                : undefined
            }
          />
        </Col>
        <Row className="pt-4">
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={() => onChg(fieldRoute, fieldOther)} variant="dark" disabled={ initialFieldOther === fieldOther && initialFieldRoute === fieldRoute }>
                Chg FPL
              </Button>
            </div>
          </Col>
          <Col xs={6}>
            <div className="d-grid gap-2">
              <Button onClick={onClose} variant="outline-secondary">
                No
              </Button>
            </div>
          </Col>
        </Row>
      </Row>
    </Container>
  );
}


interface MobileShowPdfButtonComponentProps {
  icaoPdfData: Blob | null
  data: BaseFplData
}

function MobileShowPdfButtonComponent({icaoPdfData, data}: MobileShowPdfButtonComponentProps){

  return (
    <div className="d-grid gap-2 d-md-none">
      <Button
        variant="link"
        disabled={!icaoPdfData}
        onClick={ () => {
          if (icaoPdfData) {
            var pdfURL = window.URL.createObjectURL(icaoPdfData);

            const link = document.createElement("a");
            link.download = data.flightId + "-" + moment(data.departureDateTime).utc().format("DD-MM-YY-HHmm") + "-icaoFpl.pdf";
            link.href = pdfURL;
            link.target = "_blank";
            document.body.appendChild(link);

            link.click();
          }
        }}
      >
        {icaoPdfData ? <>Show ICAO PDF</> : <>Loading PDF</>}
      </Button>
    </div>
  );
}