import { EntityDetailsDisplayInfo } from '../components/EntityDetails/EntityDetailsDisplayInfo';
import { Aircraft, AircraftWbStation } from './aircraft';
import AirctaftSarEquipment from './aircraftSar';
import { Crew } from './crew';
import { Passenger } from './passenger';
import { Route, RoutePoint, calculateDistanceBetweenRoutePoints } from './route';
import { store } from '../store/store'
import { FlightPlanPoint, FlightPlanZZZZPoint, getRoutePointFromFlightPlanPoint, getRoutePointFromFlightPlanZZZZPoint } from './flightPlan';
import { Organization } from './organization';

type Flight = {
    id?: string;

    aircraft: Aircraft;
    route?: Route
    customRoute?: {
        departure: FlightPlanPoint,
        destination: FlightPlanPoint,
        waypoints: FlightPlanZZZZPoint[],
        intermediateStops: RoutePoint[],
        aftnAddresses?: string[],
    }
    pic: Crew
    sic?: Crew
    deadheadCrew?: Crew,

    studentPilot?: Crew
    passengers: Passenger[]

    soloSupervisor?: Crew //only for training and risk assessment

    flightId: string,
    flightIdType: "reg" | "aircraft" | "crew" | "custom",
    
    passengersLeg?: FlightPassengerLeg

    altn1?: string;
    altn2?: string;
    flightRules: "V" | "I" | "Y" | "Z";
    flightType: "S" | "N" | "G" | "M" | "X";
    cruiseSpeed: {unit: "N" | "K" , value: number};
    cruiseLevel: {unit: "VFR" | "F" | "A" | "S" | "M", value: number};
    departureDateTime: Date;
    flightCategory: "AOC" | "Training" | "Other"
    trainingFlightCategory?: "Dual" | "Solo" | "Checkride"
    
    equipmentCodes: string,
    surveillanceEquipmentCodes: string
    personsOnBoard: number,
    field18Remarks: string,
    sarEquipment: AirctaftSarEquipment,
    operatorName?: string

    totalEETMins: number, //minutes
    enduranceMins: number, //minutes

    isCostSharing?: boolean
    
    // extraAftnAddresses: string[]
    
    fuelOnBoard: FlightFuelOnBoard[]
    
    wb?: FlightWb

    briefing?: FlightBriefing
    fplFile: FlightFplFile

    walletPassExternalId?: string
    checkIns?: { [key: string]: {
        time: Date,
        checkedInByUid: string
    } }

    briefingPilotAccept?: FlightBriefingPilotAccept

    postFlightData?: FlightPostFlightData

    customDocs?: FlightCustomDocs
}

export default Flight;

export type FlightPassengerLeg = {
    route: {
        from: RoutePoint
        fromIndex: number
        to: RoutePoint
        toIndex: number
    }

    boardingTime?: Date
    delayAtFrom?: number
    delayAtTo?: number
    changePaxAtFrom?: number
    changePaxAtTo?: number
}

export type FlightFuelOnBoard = {
    station: AircraftWbStation
    fuel: number
    mass: number
}

export type FlightWb = {
    status: "not-calculated" | "ok" | "error" | "no-airctaft-data",
    loads: FlightWbLoad[]  //crew, passengers, baggage, other
    errors?: string[]
}

export type FlightWbLoad = {
    station: AircraftWbStation
    mass: number
    name: string
}

export type FlightBriefingPilotAccept = {
    signatureBase64: string
    time: Date
}

export type FlightBriefing = 
    FlightBriefingError |
    FlightBriefingNotStarted |
    FlightBriefingInQueue |
    FlightBriefingInProgress |
    FlightBriefingReady

type FlightBriefingError = {
    status: "error",
    error: string
}

type FlightBriefingNotStarted = {
    status: "not-started",
}

type FlightBriefingInQueue = {
    status: "in-queue",
    routeId: string
}

type FlightBriefingInProgress = {
    status: "in-progress",
    routeId: string,
    briefingId: string
}

type FlightBriefingReady = {
    status: "ready",
    routeId: string,
    briefingId: string
}

export type FlightFplFile = 
    FlightFplFileError |
    FlightFplFileNotFiled |
    FlightFplFileReadyToFile |
    FlightFplFileFiled |
    FlightFplFileDelayed


type FlightFplFileError = {
    status: "error",
    error: string,
}

type FlightFplFileNotFiled = {
    status: "not-filed",
    fplCode: string
}

type FlightFplFileReadyToFile = {
    status: "ready-to-file",
    fplCode: string
}

type FlightFplFileFiled = {
    status: "filed",
    fplCode: string
    fillingTime: Date
    fillingTimeStr: string
}

type FlightFplFileDelayed = {
    status: "delayed",
    initialFplCode: string
    initialFillingTime: Date
    initialFillingTimeStr: string

    newTime: Date
    delayedDlaCode: string
    delayedDlaFillingTime: Date
    delayedDlaFillingTimeStr: string
}

export type FlightPostFlightData = {
    blockOffFuel?: number
    blockOnFuel?: number
    takeoffFuel?: number
    landingFuel?: number

    blockOffTime?: Date
    blockOnTime?: Date
    takeoffTime?: Date
    landingTime?: Date
}

export type FlightCustomDocs = {
    riskAssessment?: {
        createdAt: Date
        createdByUid: string
        score: number
        level: FlightRiskAssessmentLevel
        acceptanceStatus: boolean
    },
    riskAssessmentSigned?: {
        signedAt: Date
        signedByUid: string
    }
}

export type FlightRiskAssessmentLevel = {
    name: string
    maxScore: number
    requestSignFrom?: {
        name: string
        email: string
    }
}

export function getFlightRiskAssessmentLevelForScore(score: number, orgLevels: FlightRiskAssessmentLevel[]): FlightRiskAssessmentLevel {
    for (const level of orgLevels){
        if (score <= level.maxScore){
            return level
        }
    }
    throw new Error("No level found for score " + score)
}

export function getFlightDisplayInfo(): EntityDetailsDisplayInfo<Flight> {
    return {
        entityName: "Flight",
        entityCanBeDeleted: true,
        sections: [
            {
                sectionName: "Date & Time",
                sectionId: "date",
                inputs: [
                    {
                        required: true,
                        name: "Departure Date (UTC)",
                        timeName: "Departure Time (UTC)",
                        key: "departureDateTime",
                        type: "dateTime",
                        defaultValue: getDefaultDateForFlightDateTimePicker(),
                        useUtc: true,
                        dateFlatpickrOptions: {
                            dateFormat: "d-m-Y",
                        },
                        timeFlatpickrOptions: {
                            dateFormat: "H:i",
                            time_24hr: true,
                            minuteIncrement: 5,
                        },
                        validateRules: {
                            validate: {
                                inFuture: (value) => {
                                    const now = new Date();
                                    const utcNow = new Date(
                                        now.getUTCFullYear(),
                                        now.getUTCMonth(),
                                        now.getUTCDate(),
                                        now.getUTCHours(),
                                        now.getUTCMinutes()
                                    );

                                    if ((value as Date) < utcNow) {
                                        return "Departure Date and Time must be in the future";
                                    }

                                    return true;
                                }
                            }
                        }
                    }
                ]
            },
            {
                sectionName: "Flight Category",
                sectionId: "flightCategory",
                shouldDisplay: (flight) => flight.flightCategory !== "Training",
                inputs: [
                    {
                        required: true,
                        name: "Flight Category",
                        key: "flightCategory",
                        type: "dropdown",
                        options: [
                            { value: "AOC", label: "AOC" },
                            { value: "Training", label: "Training" },
                            { value: "Other", label: "Other/Private" },
                        ]
                    }
                ]
            },
            {
                sectionName: "Flight Category",
                sectionId: "flightCategory",
                shouldDisplay: (flight) => flight.flightCategory === "Training",
                inputs: [
                    {
                        required: true,
                        name: "Flight Category",
                        key: "flightCategory",
                        type: "dropdown",
                        options: [
                            { value: "AOC", label: "AOC" },
                            { value: "Training", label: "Training" },
                            { value: "Other", label: "Other/Private" },
                        ]
                    },
                    {
                        required: true,
                        name: "Training Flight Category",
                        key: "trainingFlightCategory",
                        type: "dropdown",
                        options: [
                            { value: "Dual", label: "Dual" },
                            { value: "Solo", label: "Solo" },
                            { value: "Checkride", label: "Checkride" },
                        ]
                    }
                ]
            },
            {
                sectionName: "Aircraft",
                sectionId: "aircraft",
                shouldDisplay: (flight) => flight.flightCategory !== undefined,
                inputs: [
                    {
                        required: true,
                        name: "Aircraft",
                        key: "aircraft",
                        type: "aircraft",
                        parentEntity: "Flight"
                    },
                    {
                        required: true,
                        name: "Flight ID",
                        key: "flightId",
                        flightIdTypeKey: "flightIdType",
                        type: "flightId"
                    }
                ]
            },
            {
                sectionName: "Crew",
                sectionId: "crewTraining",
                shouldDisplay: (flight) => flight.flightCategory === "Training" && flight.trainingFlightCategory === "Dual",
                inputs: [
                    {
                        name: "PIC - Flight Instructor",
                        key: "pic",
                        type: "crew",
                        required: true
                    },
                    {
                        name: "Student Pilot",
                        key: "studentPilot",
                        type: "crew",
                        required: false
                    },
                    {
                        name: "Dead Head Crew",
                        key: "deadheadCrew",
                        type: "crew",
                        required: false
                    }
                ]
            },
            {
                sectionName: "Crew",
                sectionId: "crewTraining",
                shouldDisplay: (flight) => flight.flightCategory === "Training" && flight.trainingFlightCategory === "Solo",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                        required: true
                    },
                    {
                        name: "Solo Supervisor",
                        key: "soloSupervisor",
                        type: "crew",
                        required: (store.getState().organizations.selectedOrganization?.riskAssessment.enabled ?? false)
                    }
                ]
            },
            {
                sectionName: "Crew",
                sectionId: "crewTraining",
                shouldDisplay: (flight) => flight.flightCategory === "Training" && flight.trainingFlightCategory === "Checkride",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                        required: true
                    },
                    {
                        name: "Flight Examiner",
                        key: "sic",
                        type: "crew",
                        required: false
                    },
                    {
                        name: "Dead Head Crew",
                        key: "deadheadCrew",
                        type: "crew",
                        required: false
                    }
                ]
            },

            {
                sectionName: "Crew",
                sectionId: "crewAoc",
                shouldDisplay: (flight) => flight.flightCategory === "AOC",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                        required: true
                    },
                    {
                        name: "SIC",
                        key: "sic",
                        type: "crew",
                    },
                    {
                        name: "Dead Head Crew",
                        key: "deadheadCrew",
                        type: "crew",
                    }
                ]
            },
            {
                sectionName: "Crew",
                sectionId: "crewOther",
                shouldDisplay: (flight) => flight.flightCategory === "Other",
                inputs: [
                    {
                        name: "PIC",
                        key: "pic",
                        type: "crew",
                        required: true
                    },
                    {
                        name: "SIC",
                        key: "sic",
                        type: "crew",
                    },
                    {
                        name: "Dead Head Crew",
                        key: "deadheadCrew",
                        type: "crew"
                    }
                ]
            },
            {
                sectionName: "Passengers",
                sectionId: "passengersAoc",
                shouldDisplay: (flight) => flight.flightCategory === "AOC",
                inputs: [
                    {
                        name: "Passengers",
                        key: "passengers",
                        type: "passengers",
                        requiredPassengerFields: ["lastName", "weight"]
                    },
                ]
            },
            {
                sectionName: "Passengers",
                sectionId: "passengersOther",
                shouldDisplay: (flight) => flight.flightCategory === "Other",
                inputs: [
                    {
                        name: "Passengers",
                        key: "passengers",
                        type: "passengers",
                        requiredPassengerFields: ["lastName", "weight"]
                    },
                    {
                        name: "Is Cost Sharing flight",
                        key: "isCostSharing",
                        type: "boolean",
                        required: true
                    }
                ]
            },
            {
                sectionName: "Route",
                sectionId: "route",
                shouldDisplay: (flight) => flight.aircraft !== undefined && flight.customRoute === undefined,
                inputs: [
                    {
                        parentEntity: "Flight",
                        name: "Route",
                        key: "route",
                        type: "route",
                        required: true,
                        customRouteKey: "customRoute"
                    },
                    {
                        name: "Alternate 1",
                        key: "altn1",
                        type: "text",
                        uppercase: true
                    },

                    {
                        name: "Alternate 2",
                        key: "altn2",
                        type: "text",
                        uppercase: true
                    }
                ]
            },

            {
                sectionName: "Custom Route",
                sectionId: "customRoute",
                shouldDisplay: (flight) => flight.aircraft !== undefined && flight.customRoute !== undefined,
                inputs: [
                    {
                        name: "DEP",
                        key: "customRoute.departure",
                        type: "flightPlanPoint",
                        required: true
                    },
                    {
                        name: "DEST",
                        key: "customRoute.destination",
                        type: "flightPlanPoint",
                        required: true
                    },
                    {
                        name: "Route",
                        key: "customRoute.waypoints",
                        intermediateStopsKey: "customRoute.intermediateStops",
                        type: "customRoute"
                    },
                    {
                        name: "Alternate 1",
                        key: "altn1",
                        type: "text",
                        uppercase: true
                    },
                    {
                        name: "Alternate 2",
                        key: "altn2",
                        type: "text",
                        uppercase: true
                    },
                ]
            },

            {
                sectionName: "Intermediate Stops",
                sectionId: "intermediateStops",
                shouldDisplay: (flight) => flight.aircraft !== undefined && ((flight.customRoute && flight.customRoute?.intermediateStops?.length > 0) || (flight.route !== undefined && flight.route?.intermediateStops?.length > 0)),
                inputs: [
                    {
                        name: "Passengers' Leg",
                        key: "passengersLeg",
                        type: "passengersLeg",
                    }
                ]
            },

            {
                sectionName: "Flight Info",
                sectionId: "flightInfo",
                shouldDisplay: (flight) => flight.aircraft !== undefined && (flight.route !== undefined || flight.customRoute !== undefined),
                inputs: [
                    {
                        name: "Flight Rules",
                        key: "flightRules",
                        type: "dropdown",
                        options: [
                            { value: "V", label: "(V) VFR" },
                            { value: "I", label: "(I) IFR" },
                            { value: "Y", label: "(Y) IFR->VFR" },
                            { value: "Z", label: "(Z) VFR->IFR" },
                        ],
                        required: true
                    },
                    {
                        name: "Type of Flight",
                        key: "flightType",
                        type: "dropdown",
                        options: [
                            { value: "S", label: "(S) Scheduled" },
                            { value: "N", label: "(N) Non-Scheduled" },
                            { value: "G", label: "(G) General" },
                            { value: "M", label: "(M) Military" },
                            { value: "X", label: "(X) Other" },
                        ],
                        required: true
                    },
                    {
                        name: "Cruising Speed",
                        key: "cruiseSpeed",
                        type: "dropdownInput",
                        options: [
                            { value: "N", label: "(N) Knots" },
                            { value: "K", label: "(K) Km/h" },
                        ],
                        required: true
                    },
                    {
                        name: "Cruising Level",
                        key: "cruiseLevel",
                        type: "dropdownInput",
                        options: [
                            { value: "VFR", label: "(V) VFR", hardcodedInputValue: "VFR" },
                            { value: "F", label: "(F) Fl.Lvl (100ft)" },
                            { value: "A", label: "(A) Alt. (100ft)" },
                            { value: "S", label: "(S) Std. Lvl (Dm)" },
                            { value: "M", label: "(M) Metric (Dm)" },
                        ],
                        required: true
                    },
                    {
                        name: "Total EET (mins)",
                        key: "totalEETMins",
                        type: "time-autoset",
                        getCalculatedValue: (flight) => {
                            if (!flight.cruiseSpeed || flight.cruiseSpeed.value === 0 ){
                                return 0
                            }
                            var mins: number
                            var distanceNM: number
                            if (flight.route) {
                                distanceNM = flight.route.distanceNM
                            } else if (flight.customRoute) {
                                if (flight.customRoute.departure === undefined || flight.customRoute.destination === undefined) {
                                    return 0
                                }
                                distanceNM = calculateDistanceBetweenRoutePoints(
                                    getRoutePointFromFlightPlanPoint(flight.customRoute.departure),
                                    getRoutePointFromFlightPlanPoint(flight.customRoute.destination),
                                    flight.customRoute.waypoints.map((p) => getRoutePointFromFlightPlanZZZZPoint(p)),
                                )
                            } else {
                                return 0
                            }

                            if (flight.cruiseSpeed.unit === "N") {
                                mins = distanceNM / (flight.cruiseSpeed.value / 60)
                            } else {
                                mins = distanceNM / ((flight.cruiseSpeed.value * 0.539) / 60)
                            }
                            return Math.ceil(Math.round(mins) / 5) * 5
                        },
                        required: true,
                        validateRules: {
                            min: {
                                value: 5,
                                message: "Total EET must be at least 5 mins"
                            }
                        }
                    }
                ]
            },

            {
                sectionName: "Fuel Planning",
                sectionId: "fuelPlanning",
                shouldDisplay: (flight) => flight.aircraft !== undefined && (flight.route !== undefined || flight.customRoute !== undefined),
                inputs: [
                    {
                        name: "Fuel on Board",
                        key: "fuelOnBoard",
                        type: "fuelOnBoard",
                    },
                    {
                        name: "Endurance (mins)",
                        key: "enduranceMins",
                        type: "time-autoset",
                        getCalculatedValue: (flight) => { 
                            if (!flight.aircraft || !flight.fuelOnBoard){
                                return 0
                            }
                            const totalFuelInFuelUnit = flight.fuelOnBoard.reduce((prev, curr) => prev + curr.fuel, 0)
                            const fuelPerHour = flight.aircraft.fuelPerHour
                            const perMin = fuelPerHour / 60
                            const totalMins = totalFuelInFuelUnit / perMin

                            //Round up to next 5 mins
                            return Math.ceil(Math.round(totalMins)/5)*5
                        },
                        required: true,
                        validateRules: {
                            min: {
                                value: 5,
                                message: "Endurance must be at least 5 mins"
                            }
                        }
                    }
                ]
            },

            {
                sectionName: "Flight Plan",
                sectionId: "flightPlan",
                shouldDisplay: (flight) => flight.aircraft !== undefined,
                inputs: [
                    {
                        name: "SAR Equipment",
                        key: "sarEquipment",
                        type: "aircraftSar",
                        required: true
                    },
                    {
                        name: "Equipment Codes",
                        key: "equipmentCodes",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|B|C|D|E1|E2|E3|F|G|H|I|J1|J2|J3|J4|J5|J6|J7|K|L|M1|M2|M3|O|P1|P2|P3|P4|P5|P6|P7|P8|P9|R|S|T|U|V|W|X|Y|Z)+)$|^N$/,
                                message: "Invalid equipment code"
                            }
                        }
                    },
                    {
                        name: "Surveillance Equipment Codes",
                        key: "surveillanceEquipmentCodes",
                        type: "text",
                        uppercase: true,
                        required: true,
                        validateRules: {
                            pattern: {
                                value: /^((A|C|E|H|I|L|P|S|X|B1|B2|U1|U2|V1|V2|D1|G1)+)$|^N$/,
                                message: "Invalid surveillance equipment code"
                            }
                        }
                    },
                    {
                        name: "Operator Name",
                        key: "operatorName",
                        type: "text",
                        uppercase: true,
                        required: false
                    },

                    {
                        name: "Persons on board",
                        key: "personsOnBoard",
                        type: "pob",
                        required: true
                    },
                    {
                        name: "Field 18 Remarks RMK/",
                        key: "field18Remarks",
                        type: "text",
                        uppercase: true,
                        multiline: true,
                        validateRules: {
                            pattern: {
                                value: /^([0-9]|[ '()+,=?./]|[A-Z])+$/,
                                message: "Invalid characters. Supported characters are 0-9, A-Z, space, '()+,=?./"
                            }
                        }
                    }
                ]
            },

            {
                sectionName: "AFTN Addresses",
                sectionId: "aftn",
                shouldDisplay: (flight) => flight.customRoute !== undefined,
                inputs: [
                    {
                        name: "AFTN Addresses",
                        key: "customRoute.aftnAddresses",
                        type: "aftnAddresses"
                    }
                ]
            },

            {
                sectionName: "External Boarding Pass",
                sectionId: "boardingPass",
                shouldDisplay: (flight) => store.getState().organizations.selectedOrganization?.externalWalletPass !== undefined ,
                inputs: [
                    {
                        name: store.getState().organizations.selectedOrganization?.externalWalletPass?.fieldDisplayName ?? "",
                        key: "walletPassExternalId",
                        type: "text",
                        validateRules: {
                            pattern: {
                                value: RegExp(store.getState().organizations.selectedOrganization?.externalWalletPass?.formatRegex ?? ""),
                                message: store.getState().organizations.selectedOrganization?.externalWalletPass?.formatDisplayError ?? ""
                            }   
                        }
                    }
                ]
            },

            {
                sectionName: "Weight & Balance",
                sectionId: "wb",
                shouldDisplay: (flight) => flight.aircraft !== undefined,
                inputs: [
                    {
                        name: "Check W&B",
                        key: "wb",
                        type: "wb",
                    }
                ]
            }
        ]
    }
}

function getDefaultDateForFlightDateTimePicker() {
    const now = new Date();
    const d = new Date(now.getTime() + 25 * 60 * 1000);
    d.setMinutes(Math.ceil(d.getMinutes() / 5) * 5);
    return d;
}

export function flightIsWaitingForRiskAssessment(flight: Flight, organization: Organization): boolean {
    if (organization.riskAssessment.enabled && organization.riskAssessment.disableFplFlightCategories.includes(flight.flightCategory)) {
        if (flight.customDocs?.riskAssessment === undefined) {
            return true
        } else if (flight.customDocs?.riskAssessment.level.requestSignFrom !== undefined && flight.customDocs?.riskAssessmentSigned === undefined) {
            return true
        }
    }

    return false
}