import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import { Control, FieldValues, useController, UseFormTrigger } from "react-hook-form";
import { EntityDetailsDisplayInfoFieldAftnAddresses } from "../EntityDetailsDisplayInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTowerCell, faCheck } from "@fortawesome/free-solid-svg-icons";
import { AviTracerApi } from "../../../avitracerApi";
import FlightPlan, { FlightPlanPoint, FlightPlanZZZZPoint } from "../../../models/flightPlan";
import { useEffect, useState } from "react";
import Flight from "../../../models/flight";
import deepEqual from "deep-equal";

interface EntityAftnAddressesComponentProps<Entity extends FieldValues> {
  displayInfoInput: EntityDetailsDisplayInfoFieldAftnAddresses<Entity>;
  control: Control<Entity, any>;
  flightPlanDetails?: {
    flightPlan: FlightPlan;
    trigger: UseFormTrigger<Entity>
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  };
  flightDetails?: {
    flight: Flight;
    trigger: UseFormTrigger<Entity>
    setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  }
}

function EntityAftnAddressesComponent<Entity extends FieldValues>({
  displayInfoInput,
  control,
  flightPlanDetails,
  flightDetails
}: EntityAftnAddressesComponentProps<Entity>) {

  const validateAftnsRules = () => {
    var v: any = {};
    Array.from(Array(21).keys()).forEach((_, index) => {
      v[index] = (value: string[]) => {
        if (value && value[index] && value[index].length > 0 && value[index].length !== 8) {
          return "AFTN address must be 8 characters long";
        }
        return true;
      };
    });

    return v;
  };

  const {
    field: { value, onChange, ref },
    fieldState: { error },
    formState: { isSubmitting, isValid },
  } = useController({
    name: displayInfoInput.key,
    control,
    rules: {
      validate: validateAftnsRules(),
    },
  });

  const aftns = (value as string[]) ?? [];
  const hasAftns = aftns.filter((aftn) => aftn.length > 0).length > 0;

  const [loadingAftns, setLoadingAftns] = useState(false);

  const getInitialOkState = () => {
    if (flightDetails) {
      return {
        departure: flightDetails.flight.customRoute?.departure,
        destination: flightDetails.flight.customRoute?.destination,
        waypoints: flightDetails.flight.customRoute?.waypoints,
        altn1: flightDetails.flight.altn1,
        altn2: flightDetails.flight.altn2
      }
    }else if (flightPlanDetails) {
      return {
        departure: flightPlanDetails.flightPlan.departure,
        destination: flightPlanDetails.flightPlan.destination,
        waypoints: flightPlanDetails.flightPlan.routePoints,
        altn1: flightPlanDetails.flightPlan.altn1,
        altn2: flightPlanDetails.flightPlan.altn2
      }
    }else {
      return undefined;
    }
  }

  const [okState, setOkState] = useState<{
    departure?: FlightPlanPoint
    destination?: FlightPlanPoint
    waypoints?: FlightPlanZZZZPoint[]
    altn1?: string
    altn2?: string
  } | undefined>(getInitialOkState());

  const [hasChangedRoute, setHasChangedRoute] = useState(false);

  const getAftns = async () => {
    setLoadingAftns(true);
    const { trigger, setErrorMessage } = flightPlanDetails ?? flightDetails!;
    try {
      const triggerResult = await trigger(undefined, { shouldFocus: true });
      if (triggerResult) {
        if (flightPlanDetails) {
          const { aftnAddresses } = await AviTracerApi.getFlightPlanAftnAddresses(flightPlanDetails.flightPlan);
          onChange(aftnAddresses);
        }else if (flightDetails) {
          const { aftnAddresses } = await AviTracerApi.getFlightAftnAddresses(flightDetails.flight);
          onChange(aftnAddresses);
        }
        updateOkState();
      } else {
        setLoadingAftns(false);
      }
    } catch (e: any) {
      setErrorMessage(e);
    }

    setLoadingAftns(false);
  };

  const updateOkState = () => {
    if (flightPlanDetails) {
      setOkState({
        departure: flightPlanDetails.flightPlan.departure,
        destination: flightPlanDetails.flightPlan.destination,
        waypoints: flightPlanDetails.flightPlan.routePoints,
        altn1: flightPlanDetails.flightPlan.altn1,
        altn2: flightPlanDetails.flightPlan.altn2
      });
    }else if (flightDetails) {
      setOkState({
        departure: flightDetails.flight.customRoute?.departure,
        destination: flightDetails.flight.customRoute?.destination,
        waypoints: flightDetails.flight.customRoute?.waypoints,
        altn1: flightDetails.flight.altn1,
        altn2: flightDetails.flight.altn2
      });
    }
    setHasChangedRoute(false);
  }

  useEffect(() => {
    if (!hasChangedRoute && aftns.length > 0 && okState) {
      if (flightPlanDetails) {
        if (
          !deepEqual(okState?.departure, flightPlanDetails.flightPlan.departure) ||
          !deepEqual(okState?.destination, flightPlanDetails.flightPlan.destination) ||
          !deepEqual(okState?.waypoints, flightPlanDetails.flightPlan.routePoints) ||
          okState?.altn1 !== flightPlanDetails.flightPlan.altn1 ||
          okState?.altn2 !== flightPlanDetails.flightPlan.altn2
        ) {
          setHasChangedRoute(true);
        }
      } else if (flightDetails) {
        if (
          !deepEqual(okState?.departure, flightDetails.flight.customRoute?.departure) ||
          !deepEqual(okState?.destination, flightDetails.flight.customRoute?.destination) ||
          !deepEqual(okState?.waypoints, flightDetails.flight.customRoute?.waypoints) ||
          okState?.altn1 !== flightDetails.flight.altn1 ||
          okState?.altn2 !== flightDetails.flight.altn2
        ) {
          setHasChangedRoute(true);
        }
      }
    }
  }, [hasChangedRoute, okState, flightPlanDetails, flightDetails, aftns.length]);

  return (
    <>
      {(flightPlanDetails || flightDetails) && (
        <Row>
          <div className="fw-bold">
            <Alert variant={loadingAftns || hasChangedRoute ? "warning" : hasAftns ? "success" : "danger"}>
              <div className="d-flex align-items-center justify-content-between">
                {loadingAftns ? (
                  <span>Finding AFTN addresses...</span>
                ) : hasAftns ? (
                  <span>
                    {hasChangedRoute ? (
                      <>
                        The route has changed. <div>Do you want to recalculate the AFTNs?</div>
                      </>
                    ) : (
                      <>Recalculate AFTNs?</>
                    )}
                    <div>
                      <small>Note: Previous AFTNs will be overwritten. </small>
                    </div>
                  </span>
                ) : (
                  <span>AFTN addresses not calculated yet.</span>
                )}
                <div className="d-flex flex-column ">
                  {loadingAftns ? (
                    <Spinner variant="warning" />
                  ) : (
                    <Button variant="light" onClick={getAftns} ref={ref}>
                      <FontAwesomeIcon icon={faTowerCell} color="black" className="pe-2" /> Find AFTNs
                    </Button>
                  )}
                  {hasChangedRoute && !loadingAftns && (
                    <Button variant="light" onClick={updateOkState} ref={ref} className="mt-2">
                      <FontAwesomeIcon icon={faCheck} color="black" className="pe-2" /> Mark as OK
                    </Button>
                  )}
                </div>
              </div>
            </Alert>
          </div>
        </Row>
      )}
      {value && (
        <Row className="gy-3">
          {Array.from(Array(21).keys()).map((_, index) => (
            <Col xs={12} lg={6} xl={4} key={index}>
              <div className={"input-container rounded-lg ".concat(error?.type === `${index}` ? "invalid" : "")}>
                <input
                  ref={error?.message?.includes(aftns[index]) ? ref : undefined}
                  disabled={(isValid && isSubmitting) || (displayInfoInput.disabled ?? false) || loadingAftns}
                  style={{
                    padding: "4px 16px 4px 16px",
                    height: "40px",
                    textTransform: "uppercase",
                  }}
                  placeholder="AFTN"
                  type="text"
                  className="bg-transparent"
                  onChange={(e) => {
                    onChange([...aftns.slice(0, index), e.target.value.toUpperCase(), ...aftns.slice(index + 1)]);
                  }}
                  value={aftns[index] ?? ""}
                />
              </div>
            </Col>
          ))}
          {error && <small className="text-danger">{error.message}</small>}
        </Row>
      )}
    </>
  );
}

export default EntityAftnAddressesComponent;
