import { Aircraft, AircraftFullWbProfileInfo } from "./models/aircraft";
import { Organization } from "./models/organization";
import { Passenger } from "./models/passenger";
import axios from "axios";
import { auth } from "./firebase";
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import {store} from "./store/store"
import { Crew } from "./models/crew";
import { ImportedRoute, Route, RoutePoint } from "./models/route";
import Flight, { FlightBriefing, FlightPostFlightData, FlightWb } from "./models/flight";
import { AutorouterStatus } from "./pages/auth/AutorouterAuthPage";
import { UserData, UserInvite } from "./models/userInvite";
import FlightPlan from "./models/flightPlan";
import ToolsFlight from "./models/toolsFlight";


if (process.env.NODE_ENV === "development") {
    // axios.defaults.baseURL = "http://172.20.10.2:5005"
    axios.defaults.baseURL = "http://localhost:5006"
} else {
    axios.defaults.baseURL = process.env.REACT_APP_BRIEF_API_URL
}

const briefApi = axios.create({baseURL: axios.defaults.baseURL + "/brief"})

briefApi.interceptors.request.use( async (config) => {
    
    //For Anonymous RequestInfo requests that use JWT
    if (config.headers.Authorization){
        return config
    }

    //For Shortlinks fetch that need no auth
    if (config.url?.startsWith("/shortlinks/")){
        return config
    }

    if (config.url?.startsWith("/tools/")){
        return config
    }

    if (auth.currentUser){
        config.headers["Authorization"] = "Pilot " + (await auth.currentUser.getIdToken())
    }else{
        try{
            const idToken = await getUserIdToken()
            config.headers["Authorization"] = "Pilot " + idToken
        }catch(e: any){
            if (e === "User not logged in"){
                if (window.location.pathname.startsWith("/invites/") ) {
                    window.location.href = "/auth/register?redirectTo=" + window.location.pathname
                }else{
                    window.location.href = "/auth/login" + ((window.location.pathname !== "/") ? "?redirectTo=" + window.location.pathname : "")
                }
            }else{
                throw e 
            }            
        }
    }
    
    return config
})

briefApi.interceptors.request.use( async (config) => {
    if (config.url?.includes(":orgId")){
        const orgId  = store.getState().organizations.selectedOrganizationId!
        config.url = config.url!.replace(":orgId", orgId)
    }
    
    return config
})

briefApi.interceptors.response.use((response) => {
    return response
}, async (error) => {
    if (error.response?.status === 403 || error.response?.status === 401){
        await auth.signOut()
    }
    if (error.response.data instanceof Blob){ //When ResponseType is Blob
        const jsonText: any = await (error.response!.data as Blob).text()
        const json = JSON.parse(jsonText)   
        return Promise.reject(json.error)
    }
    return Promise.reject(error.response?.data.error)
})

async function getUserIdToken(): Promise<string>{
    return new Promise((resolve, reject) => {
        const unsub = onAuthStateChanged(auth, async (user) => {
            if (user) {
                const token = await getIdToken(user);
                resolve(token)
            } else {
                reject("User not logged in")
            }
            unsub();
        });
    })
}

export module AviTracerApi {

    export async function getOrganizations(): Promise<Organization[]> {
        return (await briefApi.get("/organizations")).data
    }

    export async function getPassengers(): Promise<Passenger[]> {
        return (await briefApi.get("/organizations/:orgId/passengers")).data
    }

    export async function getPassengerWithId(passengerId: string): Promise<Passenger> {
        return (await briefApi.get("/organizations/:orgId/passengers/"+passengerId)).data
    }

    export async function savePassenger(passenger: Passenger): Promise<Passenger> {
        if (passenger.id){
            return (await briefApi.put("/organizations/:orgId/passengers/"+passenger.id, passenger)).data
        }else{
            return (await briefApi.post("/organizations/:orgId/passengers", passenger)).data
        }
    }

    export async function archivePassenger(passengerId: Passenger["id"]): Promise<void> {
        return (await briefApi.delete("/organizations/:orgId/passengers/"+passengerId+"/archive")).data
    }

    export async function passengerRequestInfoGenerateUrl(passengerId: string): Promise<{url: string}>{
        return (await briefApi.post("/organizations/:orgId/passengers/"+passengerId+"/requestInfoShortlinkGenerate")).data
    }

    export async function passengerRequestInfoGetData(token: string): Promise<{passenger: Passenger}>{
        return (await briefApi.get("/requestInfo/passenger", {headers:{"Authorization": "Bearer "+ token}})).data
    }

    export async function passengerRequestInfoSubmit(passenger: Passenger, signatureBase64: string, token: string): Promise<void>{
        return (await briefApi.post("/requestInfo/passenger",{signatureBase64, passenger}, {headers:{"Authorization": "Bearer "+ token}})).data
    }

    export async function getCrew(): Promise<Crew[]> {
        return (await briefApi.get("/organizations/:orgId/crew")).data
    }

    export async function getCrewWithId(crewId: string): Promise<Crew> {
        return (await briefApi.get("/organizations/:orgId/crew/"+crewId)).data
    }

    export async function addCrew(crew: Crew): Promise<Crew>{
        return (await briefApi.post("/organizations/:orgId/crew", crew)).data
    }
    
    export async function editCrew(crew: Crew): Promise<Crew>{
        return (await briefApi.put("/organizations/:orgId/crew/"+crew.id, crew)).data
    }

    export async function archiveCrew(crewId: Crew["id"]): Promise<void> {
        return (await briefApi.delete("/organizations/:orgId/crew/"+crewId+"/archive")).data
    }

    export async function crewRequestInfoGenerateUrl(crewId: string): Promise<{url: string}>{
        return (await briefApi.post("/organizations/:orgId/crew/"+crewId+"/requestInfoShortlinkGenerate")).data
    }

    export async function crewRequestInfoGetData(token: string): Promise<{crew: Crew}>{
        return (await briefApi.get("/requestInfo/crew", {headers:{"Authorization": "Bearer "+ token}})).data
    }

    export async function crewRequestInfoSubmit(signatureBase64: string, token: string): Promise<void>{
        return (await briefApi.post("/requestInfo/crew",{signatureBase64}, {headers:{"Authorization": "Bearer "+ token}})).data
    }

    export async function inviteCrew(crew: Crew): Promise<Crew> {
        return (await briefApi.post("/organizations/:orgId/crew/"+crew.id+"/invite")).data
    }

    export async function getUserInvite(inviteId: string): Promise<UserInvite> {
        return (await briefApi.get("/user/invites/"+inviteId)).data
    }

    export async function acceptUserInvite(inviteId: string, userData: UserData, allowOrganizationToEdit: boolean): Promise<Organization> {
        return (await briefApi.post("/user/invites/"+inviteId+"/accept", {allowOrganizationToEdit, ...userData})).data
    }

    export async function getAircraft(): Promise<Aircraft[]> {
        return (await briefApi.get("/organizations/:orgId/aircraft")).data
    }

    export async function createAircraft(registration: string): Promise<Aircraft> {
        return (await briefApi.post("/organizations/:orgId/aircraft", {registration})).data
    }

    export async function createAircraftFromShared(registration: string): Promise<Aircraft> {
        return (await briefApi.post("/organizations/:orgId/aircraft/shared", {registration})).data
    }

    export async function updateAircraft(aircraft: Aircraft): Promise<Aircraft> {
        return (await briefApi.put("/organizations/:orgId/aircraft/" + aircraft.id, aircraft)).data
    }

    export async function getAircraftWithId(aircraftId: string): Promise<Aircraft> {
        return (await briefApi.get("/organizations/:orgId/aircraft/"+aircraftId)).data
    }

    export async function getAllAircraftWbProfiles(): Promise<AircraftFullWbProfileInfo[]> {
        return (await briefApi.get("/aircraft/wbprofiles")).data
    }

    export async function getAircraftInfoByRegistration(registration: string): Promise<{icaoType: string}> {
        return (await briefApi.get("/aircraft/search/registration", {params:{registration}})).data
    }

    export async function getRoutes(): Promise<Route[]>{
        return (await briefApi.get("/organizations/:orgId/routes")).data
    }

    export async function getRouteWithId(routeId: string): Promise<Route> {
        return (await briefApi.get("/organizations/:orgId/routes/"+routeId)).data
    }

    export async function editRoute(route: Route): Promise<Route> {
        return (await briefApi.put("/organizations/:orgId/routes/"+route.id, {name: route.name, aftnAddresses: route.aftnAddresses })).data
    }

    export async function archiveRouteWithId(routeId: string): Promise<void> {
        return (await briefApi.delete("/organizations/:orgId/routes/"+routeId+"/archive")).data
    }
    
    export async function getImportedRoute(importedRouteId: string): Promise<ImportedRoute> {
        return await (await briefApi.get("/importedRoutes/"+importedRouteId)).data
    }

    export async function createImportedRouteFromMap(name: string, departure: RoutePoint, destination: RoutePoint, fixes: RoutePoint[]): Promise<{url: string}>{
        return (await briefApi.post("/importedRoutes/map", {name, departure, destination, fixes})).data
    }

    export async function createRoute(route: Route): Promise<Route> {
        return (await briefApi.post("/organizations/:orgId/routes", route)).data
    }

    export async function getFlights(): Promise<Flight[]> {
        return (await briefApi.get("/organizations/:orgId/flights")).data
    }

    export async function getFlightsInRange(from: Date, to: Date): Promise<Flight[]> {
        return (await briefApi.get("/organizations/:orgId/flights",{
            params: {
                from: from.toISOString(),
                to: to.toISOString()
            }
        })).data
    }

    export async function getFlightWithId(flightId: string): Promise<Flight> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId)).data
    }
    
    export async function editFlight(flight: Flight): Promise<Flight> {
        return (await briefApi.put("/organizations/:orgId/flights/"+flight.id, flight)).data
    }

    export async function editFlightWbLoads(flight: Flight, wb: FlightWb): Promise<Flight> {
        return (await briefApi.put("/organizations/:orgId/flights/"+flight.id+"/wbLoads", wb)).data
    }

    export async function createFlight(flight: Flight): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights", flight)).data
    }

    export async function deleteFlight(flightId: string): Promise<{id: string}> {
        return (await briefApi.delete("/organizations/:orgId/flights/"+flightId)).data
    }
    
    export async function getFlightAftnAddresses(flight: Flight): Promise<{aftnAddresses: string[]}> {
        return (await briefApi.post("/organizations/:orgId/flights/aftnAddresses",flight)).data
    }

    export async function getFlightBoardingPasses(flightId: string): Promise<{pack: {url: string}, single:{url: string, passenger: Passenger}[]}> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/boardingPasses")).data
    }

    export async function requestUpdateBoardingPasses(flightId: string): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/boardingPasses/sendPushUpdate")).data
    }

    export async function checkInBoardingPass(flightId: string, serialNumber: string): Promise<Passenger & {previousCheckIn: {time: Date, checkedInByUid: string }}> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/boardingPasses/" + serialNumber + "/checkIn")).data
    }

    export async function fileFpl(flightId: string): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+ flightId +"/fpl/file")).data
    }

    export async function refileFpl(flightId: string): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flights/"+ flightId +"/fpl/refile")).data
    }
   
    export async function cancelFpl(flightId: string): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+ flightId +"/fpl/cancel")).data
    }

    export async function delayFpl(flightId: string, newTime: Date): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+ flightId +"/fpl/delay",{newTime: newTime.toISOString()})).data
    }

    export async function chgFpl(flightId: string, f15: string, f18: string): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+ flightId +"/fpl/chg",{f15, f18})).data
    }

    export async function sendFplToEuroFpl(flightId: string): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/fpl/sendToEuroFpl")).data
    }

    export async function requestBriefing(flightId: string): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/briefing/request")).data
    }

    export async function discardBriefing(flightId: string): Promise<Flight> {
        return (await briefApi.delete("/organizations/:orgId/flights/"+flightId+"/briefing/discard")).data
    }

    export async function getBriefingStatus(flightId: string): Promise<{status: FlightBriefing["status"]}> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/briefing/status")).data
    }

    export async function getBriefingPdfUrls(flightId: string): Promise<{full: string}> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/briefing/pdfUrls")).data
    }

    export async function getPdfDoc(flightId: string, docName: string): Promise<any> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/docs/"+docName,{responseType:"blob"})).data
    }

    export async function getCustomPdfDoc(flightId: string, docName: string, getSignedDoc: boolean = false, allowEdit: boolean = false): Promise<any> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/docs/custom/" + docName,{responseType:"blob", params: {getSignedDoc, allowEdit}})).data
    }

    export async function createCustomPdfDoc(flightId: string, docName: string, blob: Blob): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/docs/custom/"+ docName, blob, {
            headers: {
                "Content-Type": "application/pdf"
            }
        })).data
    }

    export async function editCustomPdfDoc(flightId: string, docName: string, blob: Blob): Promise<void> {
        return (await briefApi.put("/organizations/:orgId/flights/"+flightId+"/docs/custom/"+ docName, blob, {
            headers: {
                "Content-Type": "application/pdf"
            }
        })).data
    }

    export async function deleteCustomPdfDoc(flightId: string, docName: string): Promise<void> {
        return (await briefApi.delete("/organizations/:orgId/flights/"+flightId+"/docs/custom/"+ docName)).data
    }

    export async function requestCustomPdfDocSignature(flightId: string, docName: string): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/docs/custom/"+ docName + "/requestSign")).data
    }

    export async function signCustomPdfDoc(flightId: string, docName: string, signatureBase64: string, acceptanceStatus: boolean): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/docs/custom/"+ docName + "/sign", {signatureBase64, acceptanceStatus})).data
    }

    export async function sendRiskAssessmentEmailReminder(flightId: string): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/docs/riskAssessment/sendEmailReminder"))
    }

    export async function uploadWbPdf(flightId: string, blob: Blob): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/docs/uploadWbPdf", blob, {
            headers: {
                "Content-Type": "application/pdf"
            }
        })).data
    }

    export async function pilotAcceptBriefing(flightId: string, signatureBase64: string, postFlightData: FlightPostFlightData): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flights/"+flightId+"/briefing/pilotAccept",{signatureBase64, postFlightData})).data
    }

    export async function getFlightWatchCookie(flightId: string): Promise<{cookie: string}> {
        return (await briefApi.get("/organizations/:orgId/flights/"+flightId+"/watch")).data
    }

    export async function getFlightPlans(): Promise<FlightPlan[]> {
        return (await briefApi.get("/organizations/:orgId/flightplans")).data
    }

    export async function getFlightPlansInRange(from: Date, to: Date): Promise<FlightPlan[]> {
        return (await briefApi.get("/organizations/:orgId/flightplans",{
            params: {
                from: from.toISOString(),
                to: to.toISOString()
            }
        })).data
    }

    export async function getFlightPlanWithId(flightPlanId: string): Promise<FlightPlan> {
        return (await briefApi.get("/organizations/:orgId/flightplans/"+flightPlanId)).data
    }

    export async function createFlightPlan(flightPlan: FlightPlan): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flightplans", flightPlan)).data
    }
    
    export async function editFlightPlan(flightPlan: FlightPlan): Promise<FlightPlan> {
        return (await briefApi.put("/organizations/:orgId/flightplans/"+flightPlan.id, flightPlan)).data
    }

    export async function deleteFlightPlan(flightPlanId: string): Promise<{id: string}> {
        return (await briefApi.delete("/organizations/:orgId/flightplans/"+flightPlanId)).data
    }

    export async function getFlightPlanPdfDoc(flightPlanId: string, docName: "icaoFpl" | "genDec" | "form731"): Promise<any> {
        return (await briefApi.get("/organizations/:orgId/flightplans/"+flightPlanId+"/docs/"+docName,{responseType:"blob"})).data
    }

    export async function fileFlightPlanFpl(flightPlanId: string): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flightplans/"+ flightPlanId +"/fpl/file")).data
    }

    export async function refileFlightPlanFpl(flightPlanId: string): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flightplans/"+ flightPlanId +"/fpl/refile")).data
    }

    export async function delayFlightPlanFpl(flightPlanId: string, newTime: Date): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flightplans/"+ flightPlanId +"/fpl/delay",{newTime: newTime.toISOString()})).data
    }

    export async function chgFlightPlanFpl(flightPlanId: string, f15: string, f18: string): Promise<Flight> {
        return (await briefApi.post("/organizations/:orgId/flightplans/"+ flightPlanId +"/fpl/chg",{f15, f18})).data
    }

    export async function cancelFlightPlanFpl(flightPlanId: string): Promise<FlightPlan> {
        return (await briefApi.post("/organizations/:orgId/flightplans/"+ flightPlanId +"/fpl/cancel")).data
    }

    export async function getFlightPlanAftnAddresses(flightPlan: FlightPlan): Promise<{aftnAddresses: string[]}> {
        return (await briefApi.post("/organizations/:orgId/flightplans/aftnAddresses",flightPlan)).data
    }

    export async function shortLinkExpandToFullUrl(token: string): Promise<{url: string}>{
        return (await briefApi.get("/shortlinks/" + token)).data
    }

    export async function autorouterExchangeCode(code: string): Promise<void> {
        return (await briefApi.post("/organizations/:orgId/autorouter/exchangeCode", { code: code })).data
    }

    export async function autorouterGetLinkStatus(): Promise<AutorouterStatus> {
        return (await briefApi.get("/organizations/:orgId/autorouter/status")).data
    }

    export async function createIndividualOrganization(data: {firstName: string, lastName: string, flightPlanPicDetails: string, weight: number}): Promise<void> {
        return (await briefApi.post("/organizations/create/individual", data))
    }

    export async function requestOrganizationCreate(data: any): Promise<void> {
        return (await briefApi.post("/organizations/requestCreate", data))
    }

    export async function requestCreditsPurchaseLink(product: "45_brief_credits"|"285_brief_credits"): Promise<{url: string}>{
        return (await briefApi.get("/organizations/:orgId/credits/purchaseLink/" + product)).data
    }

    export async function getToolsPdfDoc(toolsFlight: ToolsFlight, docName: "form731"|"gendec"|"icaofpl"): Promise<any> {
        return (await briefApi.post("/tools/flightdocs/"+docName, toolsFlight, {responseType: "blob"})).data
    }

    export async function getToolsGramet(toolsFlight: ToolsFlight): Promise<Blob> {
        return (await briefApi.post("/tools/flightdocs/gramet", toolsFlight, {responseType: "blob"})).data
    }
}
 
