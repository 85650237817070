import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit"
import { AviTracerApi } from "../avitracerApi"
import Flight, { FlightPostFlightData, FlightWb } from "../models/flight"
import { createAppAsyncThunk } from "./hooks"


interface FlightsState {
    allFlights: Flight[],
    status: "idle" | "success"
    loading: boolean
}

const initialState: FlightsState = {
    allFlights: [],
    status: "idle",
    loading: false
}

export const getFlights = createAppAsyncThunk(
    "flights/fetch",
    async () => {
        return AviTracerApi.getFlights()
    }
)

export const getFlightsInRange = createAppAsyncThunk(
    "flights/fetchRange",
    async (params: {from: Date,to: Date}) => {
        return AviTracerApi.getFlightsInRange(params.from, params.to)
    }
)

export const editFlight = createAppAsyncThunk(
    "flights/edit",
    async (flight: Flight, thunkApi) => {
        try{
            const retFlight = await AviTracerApi.editFlight(flight)
            return retFlight
        }catch(e){
            return thunkApi.rejectWithValue(e)
        }
    }
)

export const editFlightWbLoads = createAppAsyncThunk(
    "flights/editWb",
    async (data: {flight: Flight, wb: FlightWb}) => {
        return AviTracerApi.editFlightWbLoads(data.flight, data.wb)
    }
)

export const deleteFlight = createAppAsyncThunk(
    "flights/delete",
    async (flight: Flight) => {
        return AviTracerApi.deleteFlight(flight.id!)
    }
)

export const createFlight = createAppAsyncThunk(
    "flights/create",
    async (flight: Flight) => {
        return AviTracerApi.createFlight(flight)
    }
)

export const requestBriefingCreation = createAppAsyncThunk(
    "flights/requestBriefing",
    async (flight: Flight) => {
        if (flight.id === undefined){
            return Promise.reject("Flight must have an id")
        }

        return AviTracerApi.requestBriefing(flight.id)
    }
)

export const discardBriefing = createAppAsyncThunk(
    "flights/discardBriefing",
    async (flight: Flight) => {
        return AviTracerApi.discardBriefing(flight.id!)
    }
)

export const pilotAcceptBriefing = createAppAsyncThunk(
    "flights/pilotAcceptBriefing",
    async (params: {flightId: string, signatureBase64: string, postFlightData: FlightPostFlightData}) => {
        return AviTracerApi.pilotAcceptBriefing(params.flightId, params.signatureBase64, params.postFlightData)
    }
)

export const fileFpl = createAppAsyncThunk(
    "flights/fileFpl",
    async (flightId: string) => {
        return AviTracerApi.fileFpl(flightId)
    }
)

export const cancelFpl = createAppAsyncThunk(
    "flights/cancelFpl",
    async (flightId: string) => {
        return AviTracerApi.cancelFpl(flightId)
    }
)

export const delayFpl = createAppAsyncThunk(
    "flights/delayFpl",
    async (params: {flightId: string, newTime: Date}) => {
        return AviTracerApi.delayFpl(params.flightId, params.newTime)
    }
)

export const flightsSlice = createSlice({
    name: "flights",
    initialState,
    reducers:{
        resetFlightsStateForOrganizationChange: (state) => {
            state.allFlights = []
            state.status = "idle"
            state.loading = false
        },
        markFlightBriefingAsReady: (state, action: PayloadAction<Flight>) => {
            state.allFlights.find((f) => f.id === action.payload.id)!.briefing!.status = "ready"
        },
        markFlightBriefingAsInProgress: (state, action: PayloadAction<Flight>) => {
            state.allFlights.find((f) => f.id === action.payload.id)!.briefing!.status = "in-progress"
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFlights.fulfilled, (state, action) => {
            state.allFlights = mergeArrays(state.allFlights, action.payload, "id")
            state.status = "success"
        })
        builder.addCase(getFlightsInRange.fulfilled, (state, action) => {
            state.allFlights = mergeArrays(state.allFlights, action.payload, "id")
            state.status = "success"
        })
        builder.addCase(editFlight.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(editFlight.rejected, (state, action) => {
            if ('data' in (action.payload as any)){
                const index = state.allFlights.findIndex((f) => f.id === action.meta.arg.id!)
                state.allFlights[index] = (action.payload as {data: Flight}).data
            }
        })
        builder.addCase(editFlightWbLoads.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(createFlight.fulfilled, (state, action) => {
            state.allFlights.push(action.payload)
        })
        builder.addCase(deleteFlight.fulfilled, (state, action) => {
            state.allFlights = state.allFlights.filter((f) => f.id !== action.payload.id)
        })
        builder.addCase(requestBriefingCreation.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(discardBriefing.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(fileFpl.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(cancelFpl.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(delayFpl.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addCase(pilotAcceptBriefing.fulfilled, (state, action) => {
            const index = state.allFlights.findIndex((f) => f.id === action.payload.id)
            if (index === -1){
                state.allFlights.push(action.payload)
            }else{
                state.allFlights[index] = action.payload
            }
        })
        builder.addMatcher(isAnyOf(
            getFlights.pending,
            getFlightsInRange.pending,
        ), (state) => {
            state.loading = true
        })
        builder.addMatcher(isAnyOf(
            getFlights.rejected,
            getFlightsInRange.rejected,
            getFlights.fulfilled,
            getFlightsInRange.fulfilled,
        ), (state) => {
            state.loading = false
        })
    }
})

export const { resetFlightsStateForOrganizationChange, markFlightBriefingAsReady, markFlightBriefingAsInProgress } = flightsSlice.actions

export default flightsSlice.reducer

export const mergeArrays = <E extends {}> (arr1: E[], arr2: E[], key: keyof E) => {
    let merged = arr1.map((item) => {
        return {
            ...item,
            ...arr2.find((i) => i[key] === item[key]),
        }
    })
    return merged.concat(arr2.filter((i2) => !merged.find((i1) => i1[key] === i2[key])))
}