import { LoaderFunction, useLoaderData, useNavigate } from "react-router";
import { AviTracerApi } from "../../../avitracerApi";
import { EntityDisplayInfo } from "../../../components/EntityDetails/EntityDetailsDisplayInfo";
import EntityDetailsPage from "../../../components/EntityDetails/EntityDetailsPage"
import Flight from "../../../models/flight"
import { useAppDispatch } from "../../../store/hooks";
import { createFlight, deleteFlight, editFlight } from "../../../store/flightSlice";

export const flightLoader: LoaderFunction = async (params) => {
  if (params.params["flightId"]) {
      return AviTracerApi.getFlightWithId(params.params["flightId"]!);
  }

  return null;
};

function EditFlightPage(){
  const dispatch = useAppDispatch()
  const navigate = useNavigate();

  const loadedFlight = useLoaderData() as Flight;

  const flight: Flight = loadedFlight ?? {};
  
  const handleSaveFlight = async (flight: Flight) => {
    if (!flight.id) {
      return await dispatch(createFlight(flight)).unwrap()
    }else{
      return await dispatch(editFlight(flight)).unwrap()
    }
  };

  const handleDeleteFlight = async (flight: Flight) => {
    await dispatch(deleteFlight(flight)).unwrap()
  };

  const goBack = () => {
    navigate("/flights")
  }

  return (
    <EntityDetailsPage
      entity={flight}
      handleClose={goBack}
      displayInfo={EntityDisplayInfo.Flight()}
      dispatchSaveEntity={handleSaveFlight}
      dispatchDeleteEntity={handleDeleteFlight}
    />
  );
}

export default EditFlightPage