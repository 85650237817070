// Import the functions you need from the SDKs you need
import { initializeApp, FirebaseError, FirebaseOptions } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

var firebaseConfig: FirebaseOptions

if (process.env.NODE_ENV === "development" || process.env.REACT_APP_DEV_SERVER === "1") {
  firebaseConfig = {
    apiKey: "AIzaSyAvqIrOvDgllivo5NGJwtFbX7bUbGPD4u4",
    authDomain: "kdb-019e119278bd41c184a8ec2e.firebaseapp.com",
    databaseURL: "https://kdb-019e119278bd41c184a8ec2e-default-rtdb.firebaseio.com",
    projectId: "kdb-019e119278bd41c184a8ec2e",
    storageBucket: "kdb-019e119278bd41c184a8ec2e.appspot.com",
    messagingSenderId: "72211650488",
    appId: "1:72211650488:web:10918d373d23aff607a14d"
  };
} else {
  firebaseConfig = {
    apiKey: "AIzaSyAbGlTx9g4_VQlxKFPdY2CWhXNwh7hUB0Y",
    authDomain: "avitracer-app.firebaseapp.com",
    databaseURL: "https://avitracer-app-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "avitracer-app",
    storageBucket: "avitracer-app.appspot.com",
    messagingSenderId: "554469576257",
    appId: "1:554469576257:web:7abea72f025b08198600c0"
  }
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);


export const auth = getAuth(app);

if (process.env.NODE_ENV === "development") {
  connectAuthEmulator(auth, "http://localhost:9099", { disableWarnings: true });
  // connectAuthEmulator(auth, "http://172.20.10.2:9099", { disableWarnings: true });
}

export default app;

export const getFirebaseAuthErrorMessage = function getFirebaseAuthErrorMessage(error: FirebaseError) {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'The email address is already in use by another account.';
      case 'auth/invalid-email':
        return 'The email address is invalid.';
      case 'auth/operation-not-allowed':
        return 'Email and password accounts are not enabled.';
      case 'auth/weak-password':
        return 'The password is too weak.';
      case 'auth/user-not-found':
        return 'There is no user record corresponding to this email.';
      case 'auth/wrong-password':
        return 'The password is invalid for the email address.';
      default:
        return 'An error has occurred. Please try again later.';
    }
  }